/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import {
  useEffect, useState
} from "react";

import dateFormatter from "~/src/modules/date-formatter";
import { formatArea, formatEuro } from "~/src/modules/formatters";

import FeedbackModal from "~/src/features/feedback-modal";

import usePurchaseByDocumentId from "~/src/hooks/use-purchase-by-document-id.js";

import CloseIcon from "~/src/ui/icons/close-icon";

import PurchaseMap from "../maps/purchase-map";

/**
 *
 * @param {object} props - The component props
 * @param {boolean} props.active - Whether the slide is active
 * @param {Function} props.hide - Function to hide the slide
 * @param {object} props.purchase - The purchase data
 * @example
 */
export default function PurchaseSlide({
  active, activePurchaseDocId, hide
}) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [showMultipleBuyers, setShowMultipleBuyers] = useState(false);
  const [showMultipleSellers, setShowMultipleSellers] = useState(false);
  const [slideIsOpen, setSlideIsOpen] = useState(false);

  const { purchase } = usePurchaseByDocumentId({ docId: activePurchaseDocId });

  useEffect(() => {
    setShowMultipleBuyers(false);
    setShowMultipleSellers(false);
  }, [active]);

  return (
    <Dialog
      className="fixed inset-0 z-[150] overflow-hidden"
      onClose={hide}
      open={active}
    >
      <FeedbackModal
        close={() => setShowFeedback(false)}
        entity_id={purchase?.docId}
        feedback_type="transaction"
        feedback_type_label={purchase?.docId}
        show={showFeedback}
      />

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      {
        purchase
          ? (
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                afterEnter={() => {
                  setSlideIsOpen(true);
                }}
                afterLeave={() => {
                  setSlideIsOpen(false);
                }}
              >
                <DialogPanel
                  transition
                  className="pointer-events-auto relative w-screen max-w-4xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
                >
                  <TransitionChild>
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                      <button
                        className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                        onClick={hide}
                        type="button"
                      >
                        <span className="sr-only">Schließen</span>

                        <CloseIcon aria-hidden="true" className="size-6" />
                      </button>
                    </div>
                  </TransitionChild>

                  <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                    <div className="flex h-16 shrink-0 items-center justify-between bg-primary px-4 sm:px-12">
                      <h2 className="text-lg font-medium text-white" id="slide-over-heading">
                        Details zum Kaufvertrag
                      </h2>
                    </div>

                    <div className="mt-4 flex-1">
                      <dl className="px-4 sm:px-8">
                        <div className="pl-4">
                          <div className="flex items-center justify-between">
                            <h3 className="text-2xl font-semibold text-gray-700">{purchase.category}</h3>

                            <button
                              className="rounded border border-primary bg-primary px-3 py-1 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                              onClick={() => setShowFeedback(true)}
                              type="button"
                            >
                              Feedback
                            </button>
                          </div>

                          <div className="mt-4 text-gray-900">
                            <p>{purchase.stateName}</p>

                            <p className="leading-5">
                              {purchase.plz} {purchase.ort}

                              {purchase.strasse ? "," : ""} {purchase.strasse} {purchase.nr}
                            </p>
                          </div>
                        </div>

                        <div className="px-4">
                          <div className="mt-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">KV-Datum</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {dateFormatter(purchase.kvDatum)}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Verbücherungsdatum</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {dateFormatter(purchase.verbDatum)}
                            </dd>
                          </div>
                        </div>

                        <div className="mt-4 bg-gray-50">
                          {/* Buyers Section */}
                          <div className="relative px-4 pt-3 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt
                              onClick={() => purchase?.kaeufer?.length > 3 && setShowMultipleBuyers(!showMultipleBuyers)}
                              className={cn(
                                "font-semibold text-primary flex items-center h-6 w-max",
                                { "cursor-pointer group": purchase?.kaeufer?.length > 3 }
                              )}
                            >
                              <span className={cn({ "group-hover:underline": purchase?.kaeufer?.length > 3 })}>
                                Käufer
                              </span>

                              {purchase?.kaeufer?.length > 3 && (
                                <>
                                  {showMultipleBuyers
                                    ? (
                                      <button type="button">
                                        <ChevronUpIcon className="-ml-0.5 mt-0.5 size-6 text-primary" />
                                      </button>
                                    )
                                    : (
                                      <button type="button">
                                        <ChevronDownIcon className="-ml-0.5 mt-0.5 size-6 text-primary" />
                                      </button>
                                    )}

                                  <span className="ml-1 rounded-full bg-primary px-1 text-[0.7em] text-white">
                                    {purchase?.kaeufer?.length}
                                  </span>
                                </>
                              )}
                            </dt>

                            <dd className="relative leading-snug text-gray-900 sm:col-span-2">
                              {purchase && (
                                <>
                                  {purchase?.kaeufer.length > 3 && (
                                    <div className="flex flex-col gap-3">
                                      {purchase?.kaeufer.slice(0, 3).map((buyer, index) => (
                                        <div key={index}>
                                          <p>{buyer.name}</p>

                                          {buyer.fbnr && <p>{buyer.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {showMultipleBuyers && (
                                    <div className="mt-3 flex flex-col gap-3">
                                      {purchase?.kaeufer.slice(3).map((buyer, index) => (
                                        <div key={index}>
                                          <p>{buyer.name}</p>

                                          {buyer.fbnr && <p>{buyer.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {purchase?.kaeufer.length <= 3 && (
                                    <div className="flex flex-col gap-3">
                                      {purchase?.kaeufer.map((buyer, index) => (
                                        <div key={index}>
                                          <p>{buyer.name}</p>

                                          {buyer.fbnr && <p>{buyer.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </>
                              )}
                            </dd>
                          </div>

                          {/* Sellers Section */}
                          <div className="relative mt-6 px-4 pb-3 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt
                              onClick={() => purchase?.verkaeufer?.length > 3 && setShowMultipleSellers(!showMultipleSellers)}
                              className={cn(
                                "font-semibold text-gray-800 flex items-center h-6 w-max",
                                { "cursor-pointer group": purchase?.verkaeufer?.length > 3 }
                              )}
                            >
                              <span className={cn({ "group-hover:underline": purchase?.verkaeufer?.length > 3 })}>
                                Verkäufer
                              </span>

                              {purchase?.verkaeufer?.length > 3 && (
                                <>
                                  {showMultipleSellers
                                    ? (
                                      <button type="button">
                                        <ChevronUpIcon className="-ml-0.5 mt-0.5 size-6 text-gray-800" />
                                      </button>
                                    )
                                    : (
                                      <button type="button">
                                        <ChevronDownIcon className="-ml-0.5 mt-0.5 size-6 text-gray-800" />
                                      </button>
                                    )}

                                  <span className="ml-1 rounded-full bg-primary px-1 text-[0.7em] text-white">
                                    {purchase?.verkaeufer?.length}
                                  </span>
                                </>
                              )}
                            </dt>

                            <dd className="relative leading-snug text-gray-900 sm:col-span-2">
                              {purchase && (
                                <>
                                  {purchase?.verkaeufer.length > 3 && (
                                    <div className="flex flex-col gap-3">
                                      {purchase?.verkaeufer.slice(0, 3).map((seller, index) => (
                                        <div key={index}>
                                          <p>{seller.name}</p>

                                          {seller.fbnr && <p>{seller.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {showMultipleSellers && (
                                    <div className="mt-3 flex flex-col gap-3">
                                      {purchase?.verkaeufer.slice(3).map((seller, index) => (
                                        <div key={index}>
                                          <p>{seller.name}</p>

                                          {seller.fbnr && <p>{seller.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {purchase?.verkaeufer.length <= 3 && (
                                    <div className="flex flex-col gap-3">
                                      {purchase?.verkaeufer.map((seller, index) => (
                                        <div key={index}>
                                          <p>{seller.name}</p>

                                          {seller.fbnr && <p>{seller.fbnr}</p>}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </>
                              )}
                            </dd>
                          </div>
                        </div>

                        {/* Additional Details */}
                        <div className="px-4">
                          <div className="mt-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">KG</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.kgnr} {purchase.kgName}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">EZ</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.ez || "keine Angabe"}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">GST-Nr</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.grNr ? purchase.grNr.split(";").join("; ") : "keine Angabe"}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">TZ</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.tz}/{purchase.jahr}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">BG</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.bgnr} {purchase.bgname}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Anteile</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.anteil1 && purchase.anteil2
                                ? `${purchase.anteil1}/${purchase.anteil2}`
                                : "keine Angabe"}
                            </dd>
                          </div>
                        </div>

                        {/* Price and Area Details */}
                        <div className="mt-4 bg-gray-50 px-4 py-3">
                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">GST-Fläche</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.nutzFlaeche ? formatArea(purchase.gstFlaeche) : "keine Angabe"}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Nutzfläche</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.nutzFlaeche ? formatArea(purchase.nutzFlaeche) : "keine Angabe"}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Kaufpreis</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {formatEuro(Number.parseFloat(purchase.nettoPreis))}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Kaufpreis / m²</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              {purchase.m2nettoPreis
                                ? `${formatEuro(Number.parseFloat(purchase.m2nettoPreis))} / m²`
                                : "nicht kalkulierbar"}
                            </dd>
                          </div>
                        </div>

                        <div className="mt-4 px-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Flächenwidmung</dt>

                          <div className="flex flex-col gap-3 sm:col-span-2 sm:mt-0">
                            {
                              purchase?.widmungen.map((widmung, index) => {
                                const {
                                  widmungskategorie,
                                  widmungstext
                                } = widmung;

                                return (
                                  <dd className="mt-1 text-gray-900" key={`widmung_${index}`}>
                                    {widmungskategorie} - {widmungstext}
                                  </dd>
                                );
                              })
                            }
                          </div>

                        </div>

                        {purchase.lng && purchase.lat && (
                          <>
                            <div className="mt-6 text-right text-sm text-gray-900">
                              <a
                                className="inline-flex items-center hover:underline"
                                href={`https://www.immomapping.com/immomapping/Default.aspx?mapmode=1&contractOfPurchaseId=${purchase.doc_id}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <span>Transaktion auf IMMOmapping</span>

                                <ArrowTopRightOnSquareIcon className="-mr-1 -mt-0.5 ml-1 size-5 text-primary" />
                              </a>
                            </div>

                            <div className="relative mt-1 h-60">
                              <PurchaseMap
                                purchase={purchase}
                                slideIsOpen={slideIsOpen}
                              />
                            </div>
                          </>
                        )}
                      </dl>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          )
          : (<span>TEST</span>)
      }

    </Dialog>
  );
}
